<template>
  <form class="asf-input-search" role="search" :name="name" @submit="handleSubmit">
    <AsfButton
      v-e2e="'search-submit-button'"
      class="asf-input-search_submit"
      type="submit"
      :title="`${$t('search.word')}`"
      :aria-label="`${$t('search.word').toString()}`"
    >
      <AsfIcon name="search" />
    </AsfButton>
    <AsfInput
      id="query"
      v-bind="inputAttributes"
      v-model:value="searchPhrase"
      v-e2e="'search-field'"
      :label="$t('search.word')"
      :show-label="false"
      :placeholder="$t('search.word')"
      :maxlength="50"
      class="asf-input-search__field"
    />

    <AsfButton
      v-e2e="'search-reset-button'"
      :class="['asf-input-search__clear', { 'asf-input-search__clear--visible': searchPhrase }]"
      type="reset"
      :title="`${$t('forms.clearInput')}`"
      @click="clearInput"
      @keydown.enter="clearInput"
    >
      <AsfIcon name="clear" size="5" />
    </AsfButton>
  </form>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { AsfInputSearchProps } from '@ui/types'

export default defineComponent({
  name: 'AsfInputSearch',
  emits: ['search:submit', 'search:change'],
  props: {
    name: { type: String as PropType<AsfInputSearchProps['name']>, required: true },
    value: { type: String as PropType<AsfInputSearchProps['value']>, default: '' },
    inputA11y: { type: Object as PropType<AsfInputSearchProps['inputA11y']>, default: () => ({}) }
  },
  setup(props: AsfInputSearchProps, { emit }) {
    const searchPhrase = ref(props.value || '')
    const clearInput = () => (searchPhrase.value = '')

    const handleSubmit = (event: Event) => {
      event.preventDefault()
      if (searchPhrase.value) {
        emit('search:submit', searchPhrase.value)
        clearInput()
      }
    }

    watch(searchPhrase, (phrase = '') => {
      emit('search:change', phrase)
    })

    const inputAttributes = computed(() => ({
      autocapitalize: 'off',
      autocomplete: 'off',
      autocorrect: 'false',
      spellcheck: 'false',
      ...props.inputA11y
    }))

    return { searchPhrase, inputAttributes, handleSubmit, clearInput }
  }
})
</script>
<style lang="postcss">
@import '@components/molecules/InputSearch/InputSearch.css';
</style>
