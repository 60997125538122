<template>
  <AsfMenuItem
    v-e2e="`locale-item-${id}`"
    class="asf-sidebar__sliding-trigger"
    :link="link"
    :label="label"
    :show-icon="!isLink"
    v-bind="$attrs"
    @keydown="handleKeydown"
    @click="handleOpenPanel"
  >
    <template #content-icon>
      <slot name="content-icon" />
    </template>
    <template #content>
      <slot />
    </template>
  </AsfMenuItem>
</template>
<script lang="ts">
import { computed, defineComponent, inject, PropType } from 'vue'
import { AsfKeyValue, AsfSidebarSlidingItemProps } from '@ui/types'
import { OpenPanelKey } from '../Sidebar.utils'

export default defineComponent({
  name: 'AsfSidebarSlidingItem',
  props: {
    /** PanelTrigger id, this prop is used to update the SlidingPanel state. */
    id: { type: String as PropType<AsfSidebarSlidingItemProps['id']>, required: true },
    /** Label */
    label: { type: String as PropType<AsfSidebarSlidingItemProps['label']>, default: '' },
    /** Link, if true `AsfLink` will be rendered instead of `AsfButton` */
    link: { type: [String, Object] as PropType<AsfSidebarSlidingItemProps['link']>, default: '' },
    panelContent: { type: Object as PropType<AsfSidebarSlidingItemProps['panelContent']>, default: null }
  },
  setup(props: AsfSidebarSlidingItemProps, { emit }) {
    const openPanel = inject(OpenPanelKey)
    const isLink = computed(() => Boolean(props.link))

    const handleOpenPanel = () => {
      if (!openPanel || isLink.value) {
        return
      }

      openPanel(props)
    }

    const handleKeydown = (event: KeyboardEvent) => {
      let preventActions = false
      if (event.key === AsfKeyValue.RIGHT) {
        preventActions = true
        handleOpenPanel()
        emit('keydown:right')
      }

      if (preventActions) {
        event.preventDefault()
        event.stopPropagation()
      }
    }

    return { isLink, handleOpenPanel, handleKeydown }
  }
})
</script>
