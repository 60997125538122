<template>
  <div ref="slidingPanelRef" class="asf-sidebar__sliding-item" @keydown="handleKeydown">
    <div class="asf-sidebar__sliding-content">
      <!-- Main content -->
      <slot />

      <div v-if="$slots['content-action']" class="asf-sidebar__sliding-action">
        <!-- Additional content -->
        <slot name="content-action" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { FocusableElement, getFocusableChildren } from 'shared/directives'
import { AsfKeyValue, AsfSidebarSlidingContentProps, AsfSidebarSlidingPanelState } from '@ui/types'
import { ANIMATION_DURATION, CloseCurrentPanelKey, PanelStateKey } from '../Sidebar.utils'

export default defineComponent({
  name: 'AsfSlidingPanelContent',
  props: {
    level: { type: [Number, String] as PropType<AsfSidebarSlidingContentProps['level']>, default: 0 }
  },
  setup(props: AsfSidebarSlidingContentProps) {
    let disposableFocusTimout: (() => void) | null = null
    const accessibleItems = ref<FocusableElement[]>([])
    const { currentElement, setFocusToPreviousItem, setFocusToNextItem, setFocusToFirstItem, setFocusToLastItem } =
      useAccessibility(accessibleItems)

    const setFocusToElement = (el: FocusableElement) => {
      if (!el) return

      nextTick(() => {
        currentElement.value = el
        el.focus({ preventScroll: true })
      })
    }

    const closeCurrentPanel = inject(CloseCurrentPanelKey)
    const handleCloseCurrentPanel = () => closeCurrentPanel && closeCurrentPanel()

    const panelState = inject(PanelStateKey)

    if (panelState) {
      watch(panelState, (stateVal: AsfSidebarSlidingPanelState) => {
        if (stateVal.level === Number(props.level)) {
          disposableFocusTimout = timeout(
            () => setFocusToElement(currentElement.value as FocusableElement),
            ANIMATION_DURATION
          )
        }
      })
    }

    const slidingPanelRef = ref<HTMLElement | null>(null)

    const setFocusableChild = () => {
      nextTick(() => {
        if (!slidingPanelRef.value) {
          return
        }

        accessibleItems.value = getFocusableChildren(slidingPanelRef.value as HTMLElement)
        const focusAction = panelState?.isFocusFirst ? setFocusToFirstItem : setFocusToLastItem

        disposableFocusTimout = timeout(() => focusAction(setFocusToElement), ANIMATION_DURATION)
      })
    }

    const handleKeyboardSelect = (event: KeyboardEvent) => {
      const element = currentElement.value as FocusableElement
      if (element.classList.contains('asf-sidebar__sliding-trigger')) {
        event.preventDefault()
      }
    }

    const handleKeydown = (event: KeyboardEvent) => {
      let preventActions = false
      switch (event.key) {
        case AsfKeyValue.PAGEUP:
        case AsfKeyValue.HOME:
          preventActions = true
          setFocusToFirstItem(setFocusToElement)
          break

        case AsfKeyValue.PAGEDOWN:
        case AsfKeyValue.END:
          preventActions = true
          setFocusToLastItem(setFocusToElement)
          break

        case AsfKeyValue.DOWN:
          preventActions = true
          setFocusToNextItem(setFocusToElement)
          break
        case AsfKeyValue.UP:
          preventActions = true
          setFocusToPreviousItem(setFocusToElement)
          break

        case AsfKeyValue.ENTER:
        case AsfKeyValue.SPACE:
          handleKeyboardSelect(event)
          break

        case AsfKeyValue.LEFT:
          preventActions = true
          handleCloseCurrentPanel()
          break

        default:
          break
      }

      if (preventActions) {
        event.preventDefault()
        event.stopPropagation()
      }
    }

    onMounted(setFocusableChild)

    onBeforeMount(() => {
      if (disposableFocusTimout) {
        disposableFocusTimout()
      }
    })

    return { slidingPanelRef, handleKeydown }
  }
})
</script>
