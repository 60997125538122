<template>
  <component
    :is="link ? 'AsfLink' : 'AsfButton'"
    class="asf-menu-item"
    v-bind="attributes"
    v-e2e="`${label}-menu-item`"
  >
    <slot name="content-icon" />
    <span class="asf-menu-item__inner">
      <span class="asf-menu-item__label">{{ label }}</span>
      <span class="asf-menu-item__content">
        <slot name="content" />
      </span>
    </span>

    <AsfIcon v-if="showIcon && iconName" :name="iconName" size="5" class="asf-menu-item__icon" />
  </component>
</template>
<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import { AsfMenuItemProps } from '@ui/types'
import { AsfButton, AsfLink } from '#components'

export default defineComponent({
  name: 'AsfMenuItem',
  components: { AsfButton, AsfLink },
  props: {
    /** Menu-item label */
    label: { type: String as PropType<AsfMenuItemProps['label']>, default: '' },
    /** Menu-item icon (visible on mobile) */
    iconName: { type: String as PropType<AsfMenuItemProps['iconName']>, default: 'chevron-right' },
    showIcon: { type: Boolean as PropType<AsfMenuItemProps['showIcon']>, default: true },
    link: { type: [String, Object] as PropType<AsfMenuItemProps['link']>, default: null },
    prevent: { type: Boolean as PropType<AsfMenuItemProps['prevent']>, default: false }
  },
  setup(props: AsfMenuItemProps, { attrs }) {
    const attributes = computed(() => {
      const attributesProps = {
        ...attrs
      }

      if (props.link) {
        attributesProps.link = props.link
        attributesProps.prevent = props.prevent
      }

      return attributesProps
    })

    return { attributes }
  }
})
</script>
<style lang="postcss">
@import '@components/molecules/MenuItem/MenuItem.css';
</style>
