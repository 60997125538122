import { AgnosticSearchParams, AgnosticSearchResult, ErrorActions } from 'shared/types'
const logger = Logger.getLogger({ scope: 'agnostic', method: 'useSearch' })

export function useSearch(id: string) {
  const api = useEcommerce()
  const ssrKey = id || 'useSearch'
  const loading = ref<boolean>(false)
  const result = ref<AgnosticSearchResult | undefined>(undefined)

  const errors = {
    search: undefined,
    suggestionSearch: undefined,
    loadMore: undefined,
    loadPrevious: undefined
  }
  const error = ref<ErrorActions<typeof errors>>(errors)

  async function search(params: AgnosticSearchParams) {
    logger.debug(`useSearch/${ssrKey}/search`, params)

    try {
      loading.value = true
      const response = await api.getSearchFacets(params)

      if (response.ok) {
        result.value = response.payload
      }

      error.value = { ...error.value, search: undefined }
    } catch (err) {
      error.value = { ...error.value, search: { __general__: 'error.global.default' } }
      logger.error(`useSearch/${ssrKey}/search`, err)
    } finally {
      loading.value = false
    }
  }

  async function suggestionSearch(params: AgnosticSearchParams) {
    logger.debug(`useSearch/${ssrKey}/suggestionSearch`, params)

    try {
      loading.value = true
      const response = await api.getSuggestionSearch(params)

      if (response.ok) {
        result.value = response.payload
      }

      error.value = { ...error.value, suggestionSearch: undefined }
    } catch (err) {
      error.value = { ...error.value, suggestionSearch: { __general__: 'error.global.default' } }
      logger.error(`useSearch/${ssrKey}/suggestionSearch`, err)
    } finally {
      loading.value = false
    }
  }

  const loadMore = async (params: AgnosticSearchParams) => {
    logger.debug(`useSearch/${ssrKey}/loadMore`, params)

    try {
      loading.value = true
      const response = await api.getSearchFacets(params)

      if (response.ok) {
        result.value = {
          ...(result.value || response.payload),
          products: [...(result.value?.products ?? []), ...response.payload.products],
          pagination: response.payload.pagination
        }
      }

      error.value = { ...error.value, loadMore: undefined }
    } catch (err) {
      error.value = { ...error.value, loadMore: { __general__: 'error.global.default' } }
      logger.error(`useSearch/${ssrKey}/loadMore`, err)
    } finally {
      loading.value = false
    }
  }

  const loadPrevious = async (params: AgnosticSearchParams) => {
    logger.debug(`useSearch/${ssrKey}/loadPrevious`, params)

    try {
      loading.value = true
      const response = await api.getSearchFacets(params)

      if (response.ok) {
        result.value = {
          ...(result.value || response.payload),
          products: [...response.payload.products, ...(result.value?.products ?? [])],
          pagination: response.payload.pagination
        }
      }

      error.value = { ...error.value, loadPrevious: undefined }
    } catch (err) {
      error.value = { ...error.value, loadPrevious: { __general__: 'error.global.default' } }
      logger.error(`useSearch/${ssrKey}/loadPrevious`, err)
    } finally {
      loading.value = false
    }
  }

  const clearResult = async () => {
    logger.debug(`useSearch/${ssrKey}/clearResult`)

    result.value = {} as any
  }

  return {
    result: computed(() => result.value),
    loading: computed(() => loading.value),
    error: computed(() => error.value),
    clearResult,
    loadMore,
    loadPrevious,
    search,
    suggestionSearch
  }
}
